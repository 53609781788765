import { useAnalyticsContent, eventExtensionText } from '../analytics';
import { config } from '../config';
export default function useAlertAnalytics(_ref) {
  let {
    analytics,
    analyticsLabelOverride,
    onAnalyticsEvent = config().defaultAnalyticsFunction,
    variation
  } = _ref;
  // Order matters! Content comes from the heading first and falls back to body if heading doesn't exist
  const [headingRef, bodyRef] = useAnalyticsContent({
    onMount: content => {
      if (analytics !== true && (!config().alertSendsAnalytics || analytics === false)) {
        return;
      }

      // Do not send analytics event for default alerts
      if (!variation) {
        return;
      }
      const eventHeadingText = analyticsLabelOverride ?? content;
      if (!eventHeadingText) {
        console.error('No content found for Alert analytics event');
        return;
      }
      onAnalyticsEvent({
        event_name: 'alert_impression',
        event_extension: eventExtensionText,
        heading: eventHeadingText,
        type: variation
      });
    }
  });
  return {
    headingRef,
    bodyRef
  };
}