/**
 * Combines multiple refs into one, even old-style ref functions
 *
 * Borrowed from https://github.com/gregberge/react-merge-refs/blob/main/src/index.tsx
 */
export default function mergeRefs(refs) {
  return value => {
    refs.forEach(ref => {
      if (typeof ref === 'function') {
        ref(value);
      } else if (ref != null) {
        ref.current = value;
      }
    });
  };
}