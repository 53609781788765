import Video from "../../../../src/components/content/Video";
import dialogImg from "../../../../src/images/dialog-browser.png";
import camiloImg from "../../../../src/images/camilo.png";
import scssToCssImg from "../../../../src/images/scss-to-css.svg";
import * as React from 'react';
export default {
  Video,
  dialogImg,
  camiloImg,
  scssToCssImg,
  React
};