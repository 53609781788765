export function debounce(fn, ms) {
  var _this = this;
  let timer;
  return function () {
    for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(_this, args);
    }, ms);
  };
}
export default debounce;