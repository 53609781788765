import oldButtons from "../../../../src/images/old-buttons.png";
import newButtons from "../../../../src/images/new-buttons.png";
import sketchButtonStructure from "../../../../src/images/Sketch-button-architecture.png";
import sketchChangingButtons from "../../../../src/images/Sketch-changing-button-state.gif";
import sketchIconButtons from "../../../../src/images/Sketch-icon-buttons.gif";
import { Alert, Button } from '@cmsgov/design-system';
import * as React from 'react';
export default {
  oldButtons,
  newButtons,
  sketchButtonStructure,
  sketchChangingButtons,
  sketchIconButtons,
  Alert,
  Button,
  React
};