import columnsImg from "../../../../src/images/columns.png";
import gridMarginsImg from "../../../../src/images/grid-margins.png";
import guttersImg from "../../../../src/images/gutters.png";
import layoutRegionsImg from "../../../../src/images/layout-regions.png";
import * as React from 'react';
export default {
  columnsImg,
  gridMarginsImg,
  guttersImg,
  layoutRegionsImg,
  React
};