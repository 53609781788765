import GithubIcon from "../../../../src/components/icons/GithubIcon";
import JiraIcon from "../../../../src/components/icons/JiraIcon";
import SlackIcon from "../../../../src/components/icons/SlackIcon";
import EmailIcon from "../../../../src/components/icons/EmailIcon";
import * as React from 'react';
export default {
  GithubIcon,
  JiraIcon,
  SlackIcon,
  EmailIcon,
  React
};