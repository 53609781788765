import { Alert } from '@cmsgov/design-system';
import { CloseIconThin, SvgIcon } from '@cmsgov/design-system';
import colorBackground from "../../../../src/images/HCgov-logo/4_ColorBackground.png";
import photoBackground from "../../../../src/images/HCgov-logo/5_PhotoBackground.png";
import reversed from "../../../../src/images/HCgov-logo/6_Reversed.png";
import dropshadow from "../../../../src/images/HCgov-logo/7_Dropshadow.png";
import differentColors from "../../../../src/images/HCgov-logo/8_DifferentColors.png";
import outlined from "../../../../src/images/HCgov-logo/9_Outlined.png";
import squeezed from "../../../../src/images/HCgov-logo/10_Squeezed.png";
import hcgovFavicon from "../../../../src/images/HCgov-logo/favicon.ico";
import blackLogoTag from "../../../../src/images/HCgov-logo/BlackLogoTag.png";
import greyLogoTag from "../../../../src/images/HCgov-logo/GreyLogoTag.png";
import * as React from 'react';
export default {
  Alert,
  CloseIconThin,
  SvgIcon,
  colorBackground,
  photoBackground,
  reversed,
  dropshadow,
  differentColors,
  outlined,
  squeezed,
  hcgovFavicon,
  blackLogoTag,
  greyLogoTag,
  React
};