import { Alert } from '@cmsgov/design-system';
import consistentHeaderBreakpointsImg from "../../../../src/images/consistent-header/consistent-header-breakpoints.png";
import consistentHeaderCurrentSectionImg from "../../../../src/images/consistent-header/consistent-header-current-section.png";
import consistentHeaderDropdownImg from "../../../../src/images/consistent-header/consistent-header-dropdown-menus.png";
import consistentHeaderGuestImg from "../../../../src/images/consistent-header/consistent-header-guest.png";
import consistentHeaderLoggedInImg from "../../../../src/images/consistent-header/consistent-header-logged-in.png";
import consistentHeaderSlideOutImg from "../../../../src/images/consistent-header/consistent-header-slide-out-menu.png";
import consistentHeaderSubNavImg from "../../../../src/images/consistent-header/consistent-header-subnav.png";
import consistentHeaderPageLevelImg from "../../../../src/images/consistent-header/consistent-header-page-level-messages.png";
import consistentHeaderSlideOutDrawerImg from "../../../../src/images/consistent-header/consistent-header-slide-out-drawers.png";
import consistentHeaderToastImg from "../../../../src/images/consistent-header/consistent-header-toast.png";
import * as React from 'react';
export default {
  Alert,
  consistentHeaderBreakpointsImg,
  consistentHeaderCurrentSectionImg,
  consistentHeaderDropdownImg,
  consistentHeaderGuestImg,
  consistentHeaderLoggedInImg,
  consistentHeaderSlideOutImg,
  consistentHeaderSubNavImg,
  consistentHeaderPageLevelImg,
  consistentHeaderSlideOutDrawerImg,
  consistentHeaderToastImg,
  React
};