/**
 *
 * Note that this does not return the `fieldId` in the props. That is up to the component
 * to apply to the `Label` itself. Some components purposely do not set a `for` attribute
 * on their labels. These are typically components with multiple inputs wrapped in a
 * `fieldset` like `ChoiceList`, `MultiInputDateField`, and `MonthPicker`.
 */
export function useLabelProps(props) {
  const labelId = props.labelId ?? `${props.id}__label`;
  const {
    label,
    labelClassName,
    inversed
  } = props;
  return {
    children: label,
    className: labelClassName,
    id: labelId,
    inversed
  };
}
export default useLabelProps;