import { sendLinkEvent } from './analytics';
export const DEFAULTS = Object.freeze({
  errorPlacementDefault: 'top',
  defaultAnalyticsFunction: sendLinkEvent,
  alertSendsAnalytics: false,
  buttonSendsAnalytics: false,
  dialogSendsAnalytics: false,
  helpDrawerSendsAnalytics: false,
  headerSendsAnalytics: false,
  footerSendsAnalytics: false,
  thirdPartyExternalLinkSendsAnalytics: false
});
export const HEALTHCARE_DEFAULTS = {
  ...DEFAULTS,
  errorPlacementDefault: 'bottom',
  headerSendsAnalytics: true,
  footerSendsAnalytics: true,
  thirdPartyExternalLinkSendsAnalytics: true
};
const _config = {
  ...DEFAULTS
};
export function config(incomingConfig) {
  Object.assign(_config, incomingConfig);
  return _config;
}
config.DEFAULTS = DEFAULTS;
config.HEALTHCARE_DEFAULTS = HEALTHCARE_DEFAULTS;

// Deprecated stuff

function depWarning(fnName) {
  if (process.env.NODE_ENV !== 'production') {
    console.warn(`The '${fnName}' function is deprecated. Please use the global 'config' function instead.`);
  }
}
export function setDefaultAnalyticsFunction(analyticsFunction) {
  depWarning('setDefaultAnalyticsFunction');
  config({
    defaultAnalyticsFunction: analyticsFunction
  });
}
export const defaultAnalyticsFunction = config().defaultAnalyticsFunction;
export function errorPlacementDefault() {
  depWarning('setDefaultAnalyticsFunction');
  return config().errorPlacementDefault;
}
export function setErrorPlacementDefault(value) {
  depWarning('setErrorPlacementDefault');
  config({
    errorPlacementDefault: value
  });
}
export function alertSendsAnalytics() {
  depWarning('alertSendsAnalytics');
  return config().alertSendsAnalytics;
}
export function setAlertSendsAnalytics(value) {
  depWarning('setAlertSendsAnalytics');
  config({
    alertSendsAnalytics: value
  });
}
export function buttonSendsAnalytics() {
  depWarning('buttonSendsAnalytics');
  return config().buttonSendsAnalytics;
}
export function setButtonSendsAnalytics(value) {
  depWarning('setButtonSendsAnalytics');
  config({
    buttonSendsAnalytics: value
  });
}
export function dialogSendsAnalytics() {
  depWarning('dialogSendsAnalytics');
  return config().dialogSendsAnalytics;
}
export function setDialogSendsAnalytics(value) {
  depWarning('setDialogSendsAnalytics');
  config({
    dialogSendsAnalytics: value
  });
}
export function helpDrawerSendsAnalytics() {
  depWarning('helpDrawerSendsAnalytics');
  return config().helpDrawerSendsAnalytics;
}
export function setHelpDrawerSendsAnalytics(value) {
  depWarning('setHelpDrawerSendsAnalytics');
  config({
    helpDrawerSendsAnalytics: value
  });
}