import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2 {...{
      "id": "accessibility-considerations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#accessibility-considerations",
        "aria-label": "accessibility considerations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Accessibility considerations`}</h2>
    <h3 {...{
      "id": "text-contrast",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#text-contrast",
        "aria-label": "text contrast permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Text contrast`}</h3>
    <p>{`WCAG 2.0+ AA requires a contrast ratio of a minimum of 4.5:1 for normal, or body, text. Large text is easier to read, so the contrast requirement is reduced to 3:1. WCAG defines large text as text that is 24px and larger or 18.5px and larger if it is bold.`}</p>
    <p>{`Text over gradients and background images still need to meet contrast requirements.`}</p>
    <h3 {...{
      "id": "non-text-contrast",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#non-text-contrast",
        "aria-label": "non text contrast permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Non-text contrast`}</h3>
    <p>{`Elements that are not text, but still important, including buttons, icons that convey information, data visualizations (charts and graphs), and form inputs need a contrast ratio of at least 3:1. Also included in this are states of elements such as the selected state of an element, expanded vs. collapsed, active vs. inactive, elements with keyboard focus, etc.`}</p>
    <h3 {...{
      "id": "easily-test-your-color-combinations",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#easily-test-your-color-combinations",
        "aria-label": "easily test your color combinations permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Easily test your color combinations`}</h3>
    <p>{`Note that the colors listed only display colors that are available but when you are pairing colors together, make sure the color contrast ratio is sufficient. Here are three tools we suggest for testing color contrast:`}</p>
    <p><a parentName="p" {...{
        "href": "https://webaim.org/resources/contrastchecker/"
      }}>{`WebAIM Color Contrast Checker`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://dequeuniversity.com/color-contrast"
      }}>{`Deque Color Contrast Analyzer`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.tpgi.com/color-contrast-checker/"
      }}>{`Color Contrast Analyzer by TPGi`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      