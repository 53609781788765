import { Table, TableBody, TableCaption, TableCell, TableHead, TableRow } from '@cmsgov/design-system';
import * as React from 'react';
export default {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableRow,
  React
};