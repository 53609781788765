import scssToCssImg from "../../../../src/images/scss-to-css.svg";
import sketchThumbs from "../../../../src/images/sketch-3.0.1-thumbs.png";
import ssnInput from "../../../../src/images/ssn-input.gif";
import swatches from "../../../../src/images/swatches.png";
import * as React from 'react';
export default {
  scssToCssImg,
  sketchThumbs,
  ssnInput,
  swatches,
  React
};